export const ROUTE_HOME = 'home';
export const ROUTE_METER_POINTS = 'zaehlpunkte';
export const ROUTE_METER_POINT_CHANGE_REQUEST = 'zaehlpunkt-ummeldung';
export const ROUTE_CHANGE_TYPE = 'art-der-ummeldung';
export const ROUTE_PRODUCTS_AND_DATE = 'produkte-und-ummeldedatum';
export const ROUTE_CURRENT_CONTRACT_PARTNER = 'bisheriger-vertragspartner';
export const ROUTE_NEW_CONTRACT_PARTNER = 'neuer-vertragspartner';
export const ROUTE_SUMMARY = 'zusammenfassung';
export const ROUTE_PDF_UPLOAD = 'pdf-upload';
export const ROUTE_CONFIRMATION = 'bestaetigung';
export const ROUTE_INVOICES = 'rechnungen';
export const ROUTE_DSGVO = 'dsgvo';
export const ROUTE_FAQ = 'faq';
export const ROUTE_LOGOUT_SUCCESS = '#logout=success';
export const ROUTE_COMPANY_DATA = 'unternehmensdaten';
export const ROUTE_PERMISSIONS = 'berechtigungen';
export const ROUTE_PROFILE_DATA = 'profildaten';
export const ROUTE_REGISTRATION = 'registrierung';
export const ROUTE_CONDITIONS_OF_USE_WIZARD = 'nutzungsbedingungen-einwilligen';
export const ROUTE_TERMS_OF_USE = 'nutzungsbedingungen';
export const ROUTE_THANK_YOU = 'danke';
export const ROUTE_ACCOUNT_USER = 'account-user';
export const ROUTE_NOT_FOUND = 'nicht-gefunden';

// API
export const API_ROUTE_ZAEHLPUNKTE = 'zaehlpunkte';
export const API_ROUTE_INVOICES = 'rechnung';
export const API_ROUTE_COMPANY = 'unternehmen';

export const API_ROUTE_USER = 'user';
export const API_ROUTE_BERATER_OF_USER = 'user/berater';

export const API_ROUTE_PERMISSIONS = 'admin/users';
export const API_ROUTE_FEATURE_FLAGS = 'feature-flags';

export const API_ROUTE_UMMELDUNG = 'ummeldung';
export const API_ROUTE_UMMELDUNG_DOCUMENT = 'ummeldung/dokument';

export const API_ROUTE_EMAIL_EXISTANCE_CHECK = 'email/exists';

// DATEPICKER
export const FROM_DATE = 'FROM_DATE';
export const TO_DATE = 'TO_DATE';

export const UMMELDUNG_FILE_NAME_PREFIX = 'Ummeldung_';
export const UMMELDUNG_FILE_ZNR = '_ZNR';
export const UMMELDUNG_DATE_FORMAT = 'YYYYMMDD';
export const PDF_FILE_EXTENSION = '.pdf';

export function getApiUrl(apiUrl: string, route: string) {
  return `${apiUrl}/v1/ikp/auth/${route}`;
}
