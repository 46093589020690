import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@ikp/service/api/api.service';
import { IkpPopupService } from '@ikp/service/popup/ikp-popup.service';
import { UserStatus, UserStatusInfo } from '@ikp/model/user-status-info.model';
import { lastValueFrom } from 'rxjs';
import { API_ROUTE_USER, getApiUrl, ROUTE_REGISTRATION } from '@ikp/app.constants';
import { Router } from '@angular/router';
import { UserData } from '@ikp/model/user-data.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends ApiService {
  private popupService = inject(IkpPopupService);
  router = inject(Router);
  userStatusInfo = signal<UserStatusInfo>(null);

  async getUserData(): Promise<void | UserData> {
    try {
      return await lastValueFrom(this.httpClient.get<UserData>(getApiUrl(this.apiUrl, API_ROUTE_USER)));
    } catch {
      /* ignore errors */
    }
  }

  async getUserStatusInfo(): Promise<void | UserStatusInfo> {
    try {
      const userStatusInfo = await lastValueFrom(this.httpClient.get<UserStatusInfo>(getApiUrl(this.apiUrl, API_ROUTE_USER + '/status')));
      this.userStatusInfo.set(userStatusInfo);
      return userStatusInfo;
    } catch {
      this.handleError();
    }
  }

  registerUser(): Promise<void> {
    return lastValueFrom(this.httpClient.post(getApiUrl(this.apiUrl, `${API_ROUTE_USER}/register`), {}, { observe: 'response' }))
      .then(response => {
        if (response.status === 200) {
          this.router.navigate([ROUTE_REGISTRATION]);
          this.userStatusInfo().status = UserStatus.VERIFICATION_PENDING;
        }
      })
      .catch(() => {
        this.router.navigateByUrl('/registration');
      });
  }

  private handleError(): void {
    this.popupService.handleErrorPopup('userstatus-error', 'global.errors.user.title');
  }
}
